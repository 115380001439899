@use "../global" as *;

.l-header {
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 2.2rem;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @include mq-down(md) {
    top: 0;
  }

  &__inner {
    background-color: transparent;
    width: 100%;
    max-width: 120rem;
    height: fit-content;
    display: flex;
    flex-flow: row wrap;

    @include mq-down(md) {
      justify-content: center;
    }

    .company-logo {
      overflow: hidden;
      width: 13rem;
      margin-top: 1.2rem;

      @include mq-down(md) {
        width: ss(160);
        margin-top: ss(12);
      }
    }
  }
  .menu {
    display: flex;
    flex-flow: row wrap;
    gap: 3.6rem;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 4.5rem 3rem 5.5rem;

    @include mq-down(md) {
      gap: ss(24);
      font-size: ss(28);
      padding: ss(60) ss(20);
    }
  }
}
