@use "sass:math";

// breakpoint-up ※基本こちらをベースとして採用（モバイルファーストstyle（Bootstrap方式））
@mixin mq-up($breakpoint) {
  @media screen and (min-width: 751px) {
    @content;
  }
}

// breakpoint-down
@mixin mq-down($breakpoint) {
  @media screen and (max-width: 750px) {
    @content;
  }
}

@function ss($size) {
  @if quote(type-of($size)) == "number" {
    @return 100vw * math.div($size, 750);
  } @else {
    @return $size;
  }
}
