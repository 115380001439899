@use "@global" as *;

.service {
  width: 100%;
  padding: 0 6rem;
  margin: 0 auto;
  margin-bottom: 17rem;

  @include mq-down(md) {
    padding: 0 ss(60);
    margin-bottom: ss(240);
  }

  &__background {
    position: relative;
    top: 61rem;
    left: -168.96rem;
    transform: skewY(150deg);
    background: #ff4d00;
    width: 288rem;
    height: 124rem;
    margin-bottom: -124rem;
    pointer-events: none;

    @include mq-down(md) {
      top: ss(450);
      left: ss(-1689.6);
      width: ss(2880);
      height: ss(1240);
      margin-bottom: ss(-1240);
    }
  }
  &__logo {
    position: relative;
    top: -13rem;
    left: -74rem;
    transform: rotateY(180deg);
    width: 107.1rem;
    margin-bottom: -164.1rem;
    pointer-events: none;
    img {
      filter: invert(93%) sepia(93%) saturate(0%) hue-rotate(279deg)
        brightness(108%) contrast(108%);
    }

    @include mq-down(md) {
      top: ss(-320);
      left: ss(-740);
      width: ss(1071);
      height: ss(1641);
      margin-bottom: ss(-1641);
    }
  }

  &__inner {
    position: relative;
  }
  &__title {
    width: fit-content;
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    margin-bottom: 6rem;

    @include mq-down(md) {
      margin-bottom: ss(80);
    }

    &__wrapper {
      display: flex;
      flex-flow: column wrap;
    }

    .title-image {
      margin: 0 1.6rem 0 0;

      @include mq-down(md) {
        margin: 0 ss(16) 0 0;
        width: ss(55);
      }
    }
    h2 {
      font-size: 4.2rem;
      font-weight: bold;
      line-height: normal;
      margin: 0.5rem 1rem 1rem;

      @include mq-down(md) {
        font-size: ss(42);
        margin: ss(5) ss(10) ss(10);
      }
    }
    small {
      font-size: 1.5rem;
      font-weight: medium;
      line-height: normal;
      color: $color-main-accent;
      margin: 0 1rem;

      @include mq-down(md) {
        font-size: ss(15);
        margin: 0 ss(10);
      }
    }
  }

  .service-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 6rem;

    @include mq-down(md) {
      gap: ss(60);
    }

    .service-item {
      overflow: hidden;
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      max-width: 51rem;
      max-height: 43rem;
      background: #fff;
      border-radius: 1.6rem;

      @include mq-down(md) {
        max-width: ss(630);
        max-height: fit-content;
        border-radius: ss(16);
      }

      &__photo {
        overflow: hidden;
        max-height: 26.8rem;

        @include mq-down(md) {
          max-height: ss(268);

          > img {
            transform: translateY(-15%);
          }
        }
      }
      &__description {
        height: 16.2rem;
        padding: 2rem;

        @include mq-down(md) {
          height: fit-content;
          padding: ss(20);
        }

        h3 {
          font-size: 2.6rem;
          font-weight: bold;
          line-height: normal;

          @include mq-down(md) {
            font-size: ss(32);
          }
        }
        p {
          font-size: 1.8rem;
          line-height: normal;
          margin-top: 1.2rem;

          @include mq-down(md) {
            font-size: ss(26);
            margin-top: ss(12);
          }
        }
      }
    }
  }
}
