@use "../global" as *;

.l-footer {
  position: relative;
  background: $color-font-black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  margin-top: auto;

  &__inner {
    width: 100%;
    max-width: 120rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.6rem;
    padding: 5rem 6rem 11rem;

    @include mq-down(md) {
      padding: ss(60) ss(50) ss(130);
    }
  }
  .company-info {
    &__logo {
      width: 13.6rem;

      @include mq-down(md) {
        width: ss(136);
      }
    }
    &__list {
      margin: 2rem 0 1.2rem;

      @include mq-down(md) {
        font-size: ss(22);
        margin: ss(20) 0 ss(12);
      }
    }
    .copyright {
      font-size: 1.2rem;

      @include mq-down(md) {
        font-size: ss(18);
      }
    }
  }
  .menu {
    display: flex;
    flex-flow: row wrap;
    gap: 3.5rem;
    font-weight: bold;
    padding: 0 3.5rem;

    @include mq-down(md) {
      flex-flow: column wrap;
      align-items: flex-end;
      gap: ss(16);
      font-size: ss(24);
      padding: 0 ss(24);
      white-space: nowrap;
    }
  }
}
