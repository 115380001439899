@use "@global" as *;

.mission {
  width: 100%;
  margin: 0 auto;
  padding: 5rem 6rem 0;
  margin-bottom: 17rem;

  @include mq-down(md) {
    padding: ss(50) ss(60) 0;
    margin-bottom: ss(170);
  }

  &__background {
    position: relative;
    top: -26rem;
    left: -168.96rem;
    transform: skewY(-150deg);
    background: #f5f5f5;
    width: 288rem;
    height: 55rem;
    margin-bottom: -55rem;
    pointer-events: none;

    @include mq-down(md) {
      top: ss(-260);
      left: ss(-1689.6);
      width: ss(2880);
      height: ss(550);
      margin-bottom: ss(-550);
    }
  }

  &__inner {
    position: relative;
    padding: 17.7rem 4.8rem 8.7rem 0;

    @include mq-down(md) {
      padding: ss(177) 0 ss(87) 0;
    }
  }
  &__title {
    width: fit-content;
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    margin-bottom: 6rem;

    @include mq-down(md) {
      margin-bottom: ss(80);
    }

    &__wrapper {
      display: flex;
      flex-flow: column wrap;
    }

    .title-image {
      margin: 0 1.6rem 0 0;
      filter: invert(42%) sepia(57%) saturate(7493%) hue-rotate(9deg)
        brightness(110%) contrast(102%);

      @include mq-down(md) {
        margin: 0 ss(16) 0 0;
        width: ss(55);
      }
    }
    h2 {
      font-size: 4.2rem;
      font-weight: bold;
      line-height: normal;
      margin: 0.5rem 1rem 1rem;

      @include mq-down(md) {
        font-size: ss(42);
        margin: ss(5) ss(10) ss(10);
      }
    }
    small {
      font-size: 1.5rem;
      font-weight: medium;
      line-height: normal;
      color: $color-main-accent;
      margin: 0 1rem;

      @include mq-down(md) {
        font-size: ss(15);
        margin: 0 ss(10);
      }
    }
  }
  &__body {
    width: 100%;
    max-width: 56rem;
    margin-left: auto;

    @include mq-down(md) {
      max-width: ss(436);
    }

    p {
      font-size: 2.2rem;
      font-weight: bold;
      line-height: normal;

      @include mq-down(md) {
        font-size: ss(32);
      }
    }
    p + p {
      margin-top: 2.8rem;

      @include mq-down(md) {
        margin-top: ss(28);
      }
    }
  }
}
