@use "@global" as *;

.overview {
  position: relative;
  max-width: 120rem;
  background: #f5f5f5;
  border-radius: 1.6rem;
  margin-bottom: 19rem;
  isolation: isolate;

  @include mq-down(md) {
    max-width: ss(710);
    border-radius: ss(16);
    margin: 0 ss(20);
    margin-bottom: ss(190);
    isolation: auto;
  }

  &__inner {
    padding: 8rem 6rem 13.4rem;

    @include mq-down(md) {
      padding: ss(60) ss(40) ss(70);
    }
  }

  &__logo {
    position: relative;
    top: -3rem;
    right: -80rem;
    width: 107.1rem;
    height: 164.1rem;
    margin-bottom: -164.1rem;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    img {
      filter: invert(100%) sepia(0%) saturate(43%) hue-rotate(56deg)
        brightness(115%) contrast(92%);
    }

    @include mq-down(md) {
      top: ss(280);
      right: ss(-465);
      width: ss(600);
      height: ss(863.68);
      margin-bottom: ss(-865);
    }
  }
  &__title {
    width: fit-content;
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    margin-bottom: 6rem;

    @include mq-down(md) {
      margin-bottom: ss(60);
    }

    &__wrapper {
      display: flex;
      flex-flow: column wrap;
    }

    .title-image {
      margin: 0 1.6rem 0 0;
      filter: invert(42%) sepia(57%) saturate(7493%) hue-rotate(9deg)
        brightness(110%) contrast(102%);

      @include mq-down(md) {
        margin: 0 ss(16) 0 0;
        width: ss(55);
      }
    }
    h2 {
      font-size: 4.2rem;
      font-weight: bold;
      line-height: normal;
      margin: 0.5rem 1rem 1rem;

      @include mq-down(md) {
        font-size: ss(42);
        margin: ss(5) ss(10) ss(10);
      }
    }
    small {
      font-size: 1.5rem;
      font-weight: medium;
      line-height: normal;
      color: $color-main-accent;
      margin: 0 1rem;

      @include mq-down(md) {
        font-size: ss(15);
        margin: 0 ss(10);
      }
    }
  }

  .company-overview {
    position: relative;
    padding: 0 15rem;

    @include mq-down(md) {
      padding: 0 ss(30);
    }
  }
  .overview-items {
    .overview-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 2.8rem 0;

      @include mq-down(md) {
        padding: ss(28) 0;
      }

      p {
        font-size: 1.8rem;
        line-height: 3.2rem;

        @include mq-down(md) {
          font-size: ss(26);
          line-height: ss(32);
        }
      }
      p.label {
        flex-basis: 25%;
        font-weight: bold;

        @include mq-down(md) {
          flex-basis: 36%;
        }
      }
      p.detail,
      .service-detail {
        flex-basis: 75%;

        @include mq-down(md) {
          flex-basis: 64%;
        }
      }
      .detail-items {
        .detail-item {
          font-size: 1.8rem;
          list-style-type: decimal;
          list-style-position: inside;

          @include mq-down(md) {
            font-size: ss(26);
            list-style-position: outside;
            margin-left: 1em;
            margin-right: -1em;
          }
        }
      }
    }
    .overview-item + .overview-item {
      border-top: 0.1rem solid #e6e6e6;
    }
    .overview-item:last-child {
      @include mq-down(md) {
        border-bottom: 0.1rem solid #e6e6e6;
      }
    }
  }
}
