@use "@global" as *;

.mv {
  position: relative;
  width: 100%;
  max-width: 128rem;
  padding-top: 15.2rem;
  margin: 0 auto;

  @include mq-down(md) {
    padding-top: ss(152);
  }

  &__photo {
    position: absolute;
    top: 0;
    right: -13rem;
    width: 100%;
    max-width: 80rem;
    mask-image: url("~@image/logo_markonly.svg");
    mask-position: 4% 65%;
    mask-size: 100%;
    mask-repeat: no-repeat;
    pointer-events: none;

    @include mq-down(md) {
      right: ss(-385);
    }
  }

  &__inner {
    margin: 16.3rem 12.6rem 26.7rem;

    @include mq-down(md) {
      margin: ss(163) ss(72) ss(283);
    }
  }
  &__lead {
    position: relative;
    white-space: nowrap;

    h1 {
      font-size: 5.6rem;
      font-weight: bold;
      line-height: normal;

      @include mq-down(md) {
        font-size: ss(72);
        line-height: ss(95);
      }

      span {
        color: $color-main-accent;
      }
    }
    p {
      font-size: 2.2rem;
      font-weight: bold;
      line-height: normal;

      @include mq-down(md) {
        font-size: ss(32);
      }
    }
    h1 + p {
      margin-top: 2.8rem;

      @include mq-down(md) {
        margin-top: ss(32);
      }
    }
  }
}
