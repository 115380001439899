@use "../global" as *;

*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 62.5%; // 10px = 1rem
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
body {
  line-height: 1.7;
  width: 100%;
  max-width: 100vw;
  height: auto;
  min-height: 100vh;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: $color-font-black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
}
#vue-app {
  width: inherit;
  height: inherit;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
a {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s;
  opacity: 1;
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  vertical-align: middle;
}
input {
  margin: 0;
  padding: 0;
}
textarea {
  padding: 0;
  font: inherit;
  color: inherit;
}
button {
  font: inherit;
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
}
.d-none,
.d-none-loaded {
  display: none;
}
.list-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
br.mobile {
  @include mq-up(md) {
    display: none;
  }
}
