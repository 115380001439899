@use "../global" as *;

.l-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__inner {
    height: 100%;
    width: 100%;
  }
}
