@use "../global" as *;

.l-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 100%;
    max-width: 120rem;
    height: 100%;
  }
}
